<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetAll"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ (isAdd ? $t('actions.add') : $t('actions.edit')) + " " + $tc("shared_tenants.title", 1) }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="tenant"
            rules="required"
          >
            <b-form-group
              label="Tenant"
              label-for="tenant_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.tenant.id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenant_id"
                @input="changeTenant"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Permissions -->
          <validation-provider #default="validationContext" name="permissions">
            <b-form-group
              class="text-capitalize"
              :label="$t('shared_tenant.permissions')"
              label-for="permissions"
              :state="getValidationState(validationContext)"
            >
            <div class="text-lowercase">
              <v-select
                v-model="permissionsArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="permissionsOptions"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="permissions"
                class="text-lowercase"
                :create-option="option => ({value: option, label: option.toLowerCase()})"
              />
            </div>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type Alarms -->
          <validation-provider #default="validationContext" name="type_alarms">
            <b-form-group
              class="text-capitalize"
              :label="$t('shared_tenant.type_alarms')"
              label-for="type_alarms"
              :state="getValidationState(validationContext)"
            >
            <div class="text-lowercase">
              <v-select
                v-model="typeAlarmsArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeAlarmsOptions"
                multiple
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="type_alarms"
                class="text-lowercase"
                :create-option="option => ({value: option, label: option.toLowerCase()})"
              />
            </div>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          

     

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") :$t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
             {{$t('actions.cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {

    const permissionsArray = ref([]);
    const typeAlarmsArray = ref([]);
    const tenantOptions = ref([]);
    const permissionsOptions = ref([]);
    const typeAlarmsOptions  = ref([]);

    const idUser = ref(parseInt(router.currentRoute.params.id_user));


    const blankData = {
        tenant: {id : null},
        permissions: [],
        types_alarms: []
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));

    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };



    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        dispatch = store.dispatch("shared_tenant/add", {
          id_tenant: itemData.value.tenant.id,
          id_user: idUser.value,
          permissions: permissionsArray.value,
          types_alarms: typeAlarmsArray.value
        })
      } else {
        const req = {
          id: itemData.value.id,
          params: {
            id_tenant: itemData.value.tenant.id,
            id_user: idUser.value,
            permissions: permissionsArray.value,
            types_alarms: typeAlarmsArray.value
          },
        };

        dispatch = store.dispatch("shared_tenant/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const resetAll = () => {
      resetData();
      permissionsArray.value = []
      typeAlarmsArray.value = []
      tenantOptions.value = []
      permissionsOptions.value = []
      typeAlarmsOptions.value = []
    }

    const loadData = async() => {
      let tenantStore = await store.dispatch('shared_tenant/getAvaibleTenants', idUser.value )
      tenantOptions.value = store.getters['shared_tenant/getAvaibleTenants']
      
      if (!props.isAdd) {
        tenantOptions.value.push({value : props.itemEdit.tenant.id, label: props.itemEdit.tenant.name})
        itemData.value = props.itemEdit

        let permissionsStore = await store.dispatch('shared_tenant/getAvaiblePermissions',  itemData.value.tenant.id )
        permissionsOptions.value = store.getters['shared_tenant/getAvaiblePermissions']

        let typeAlarmStore = await store.dispatch('shared_tenant/getAvaibleTypeAlarms',  itemData.value.tenant.id )
        typeAlarmsOptions.value = store.getters['shared_tenant/getAvaibleTypeAlarms']
        
        permissionsArray.value = props.itemEdit.permissions.map(
          (item) => item.id
        );
        typeAlarmsArray.value = props.itemEdit.type_alarms.map(
          (item) => item.id
        );
      }


    };

    const getAvaibleTentants = async() => {
      let tenantStore = await store.dispatch('shared_tenant/getAvaibleTenants', idUser.value )
      tenantOptions.value = store.getters['shared_tenant/getAvaibleTenants']
      console.log(tenantOptions.value)
    }

    const getAvaiblePermissions = async() => {
      if(itemData.value.tenant.id != null) {
        let permissionsStore = await store.dispatch('shared_tenant/getAvaiblePermissions',  itemData.value.tenant.id )
        permissionsOptions.value = store.getters['shared_tenant/getAvaiblePermissions']
      }else{
        permissionsOptions.value = []
      }
    }

    const getAvaibleTypeAlarms = async() => {
      if(itemData.value.tenant.id != null){
        let typeAlarmStore = await store.dispatch('shared_tenant/getAvaibleTypeAlarms',  itemData.value.tenant.id )
        typeAlarmsOptions.value = store.getters['shared_tenant/getAvaibleTypeAlarms']
      }else{
        typeAlarmsOptions.value = []
      }
    }

    const changeTenant = () => {
      permissionsArray.value = []
      typeAlarmsArray.value = []
      permissionsOptions.value = []
      typeAlarmsOptions.value = []
      getAvaiblePermissions()
      getAvaibleTypeAlarms()
    }
    
    getAvaibleTentants()
    getAvaiblePermissions()
    getAvaibleTypeAlarms()

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetAll,
      loadData,
      resetForm,

      permissionsArray,
      typeAlarmsArray,
      idUser,

      tenantOptions,
      permissionsOptions,
      typeAlarmsOptions,

      getAvaibleTentants,
      getAvaiblePermissions,
      getAvaibleTypeAlarms,
      changeTenant,

    };
  },
};
</script>